@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

button .top-btn-position{
    position: fixed;
    bottom: 15px;
    right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid #000;
    color: #333;
    cursor: pointer;
    z-index: 1;
}

@media only screen and (max-width: 600px){
    button .top-btn-position{
        position: fixed;
        bottom: 25px;
        right: 15px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid #000;
        color: #333;
        cursor: pointer;
        z-index: 1;
    }
}



.orange {
    color: #ff7a01;
  }
  
  .form-container {
    position: relative;
    max-width: 700px;
    margin:0 0 20px  400px;
    background-color: #001925;
    padding: 30px;
    border-left: 5px solid #ff7a01;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
  }

  .form-container .form{
    margin-top: 100px;
  }
  
  .heading {
    display: block;
    color: white;
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
  
  .form-container .form .input {
    color: #87a4b6;
    width: 100%;
    background-color: #002733;
    border: none;
    outline: none;
    padding: 10px;
    margin-bottom: 20px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    border-left: 1px solid transparent;
  }
  
  .form-container .form .input:focus {
    border-left: 5px solid #ff7a01;
  }
  
  .form-container .form .textarea {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #013747;
    color: #ff7a01;
    font-weight: bold;
    resize: none;
    max-height: 150px;
    margin-bottom: 20px ;
    border-left: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .textarea:focus {
    border-left: 5px solid #ff7a01;
  }
  
  .form-container .form .button-container {
    display: flex;
    gap: 10px;
  }
  
  .form-container .form .button-container .send-button {
    flex-basis: 70%;
    background: #ff7a01;
    padding: 10px;
    color: #001925;
    text-align: center;
    font-weight: bold;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .button-container .send-button:hover {
    background: transparent;
    border: 1px solid #ff7a01;
    color: #ff7a01;
  }
  
  .form-container .form .button-container .reset-button-container {
    filter: drop-shadow(1px 1px 0px #ff7a01);
    flex-basis: 30%;
  }
  
  .form-container .form .button-container .reset-button-container .reset-button {
    position: relative;
    text-align: center;
    padding: 10px;
    color: #ff7a01;
    font-weight: bold;
    background: #001925;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .button-container .reset-button-container .reset-button:hover {
    background: #ff7a01;
    color: #001925;
  }

  @media only screen and (max-width: 600px){
    
.orange {
    color: #ff7a01;
  }
  
  .form-container {
    max-width: 700px;
    margin: 30px;
    background-color: #001925;
    padding: 30px;
    border-left: 5px solid #ff7a01;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
  }
  .form-container .form{
    margin-top: 100px;
  }
  
  .heading {
    display: block;
    color: white;
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 20px;
  }
  
  .form-container .form .input {
    color: #87a4b6;
    width: 100%;
    background-color: #002733;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    border-left: 1px solid transparent;
  }
  
  .form-container .form .input:focus {
    border-left: 5px solid #ff7a01;
  }
  
  .form-container .form .textarea {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: #013747;
    color: #ff7a01;
    font-weight: bold;
    resize: none;
    max-height: 150px;
    margin-bottom: 20px;
    border-left: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .textarea:focus {
    border-left: 5px solid #ff7a01;
  }
  
  .form-container .form .button-container {
    display: flex;
    gap: 10px;
  }
  
  .form-container .form .button-container .send-button {
    flex-basis: 70%;
    background: #ff7a01;
    padding: 10px;
    color: #001925;
    text-align: center;
    font-weight: bold;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .button-container .send-button:hover {
    background: transparent;
    border: 1px solid #ff7a01;
    color: #ff7a01;
  }
  
  .form-container .form .button-container .reset-button-container {
    filter: drop-shadow(1px 1px 0px #ff7a01);
    flex-basis: 30%;
  }
  
  .form-container .form .button-container .reset-button-container .reset-button {
    position: relative;
    text-align: center;
    padding: 10px;
    color: #ff7a01;
    font-weight: bold;
    background: #001925;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
    transition: all 0.2s ease-in-out;
  }
  
  .form-container .form .button-container .reset-button-container .reset-button:hover {
    background: #ff7a01;
    color: #001925;
  }
  }



  @media only screen and (min-width: 610px) and (max-width: 1024px){
    

.orange {
  color: #ff7a01;
}

.form-container {
  position: relative;
  max-width: 500px;
  margin:0 0 20px 20px;
  background-color: #001925;
  padding: 30px;
  border-left: 5px solid #ff7a01;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}

.form-container .form{
  margin-top: 100px;
}

.heading {
  display: block;
  color: white;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 20px;
}

.form-container .form .input {
  color: #87a4b6;
  width: 100%;
  background-color: #002733;
  border: none;
  outline: none;
  padding: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  border-left: 1px solid transparent;
}

.form-container .form .input:focus {
  border-left: 5px solid #ff7a01;
}

.form-container .form .textarea {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #013747;
  color: #ff7a01;
  font-weight: bold;
  resize: none;
  max-height: 150px;
  margin-bottom: 20px ;
  border-left: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.form-container .form .textarea:focus {
  border-left: 5px solid #ff7a01;
}

.form-container .form .button-container {
  display: flex;
  gap: 10px;
}

.form-container .form .button-container .send-button {
  flex-basis: 70%;
  background: #ff7a01;
  padding: 10px;
  color: #001925;
  text-align: center;
  font-weight: bold;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.form-container .form .button-container .send-button:hover {
  background: transparent;
  border: 1px solid #ff7a01;
  color: #ff7a01;
}

.form-container .form .button-container .reset-button-container {
  filter: drop-shadow(1px 1px 0px #ff7a01);
  flex-basis: 30%;
}

.form-container .form .button-container .reset-button-container .reset-button {
  position: relative;
  text-align: center;
  padding: 10px;
  color: #ff7a01;
  font-weight: bold;
  background: #001925;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  transition: all 0.2s ease-in-out;
}

.form-container .form .button-container .reset-button-container .reset-button:hover {
  background: #ff7a01;
  color: #001925;
}

  }

  .container .my-contacts{
    display: block;
    margin: 120px 0 20px 0;
    text-align: center;
  }
  .container .my-contacts .whatsapp{
    margin-left: 400px;
    margin-top: 14px;
    padding-top: 10px;
    width: 270px;
    height: 80px;
    background-color: #001925;
    box-shadow: orange;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
  }
  .container .my-contacts .whatsapp a{
    color: #fff;
    font-size: 40px;
    text-decoration: none;
    
  }
  .container .my-contacts .whatsapp a .fa-whatsapp{
    color: #fff;
    font-weight: 600;
    height: 45px;
    width: 45px;
    background-color: #14a76c;
    border-radius: 10px;
    margin-right: 12px;
  }
  .container .my-contacts .instagram{
    margin-left: 400px;
    margin-top: 14px;
    padding-top: 10px;
    width: 270px;
    height: 80px;
    background-color: #001925;
    box-shadow: orange;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
  }
.container .my-contacts .instagram a{
  margin-top: 10px;
  color: #fff;
  font-size: 40px;
  text-decoration: none;
}

.container .my-contacts .instagram a .fa-instagram{
  color: transparent;
  background: -webkit-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  margin-right: 10px;
}
.container .my-contacts .snapchat{
  margin-left: 400px;
  margin-top: 14px;
  padding-top: 10px;
  width: 270px;
  height: 80px;
  background-color: #001925;
  box-shadow: orange;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}
.container .my-contacts .snapchat a{
  color: #fff;
  font-size: 40px;
  text-decoration: none;
  
}

.container .my-contacts .snapchat a .fa-snapchat{
  color: #fff;
  font-size: 40px;
}

.container .my-contacts .landline{
  margin-left: 400px;
  color: #fff;
  font-size: 40px;
    margin-top: 14px;
    padding-top: 10px;
    width: 270px;
    height: 80px;
    background-color: #001925;
    box-shadow: orange;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}


@media only screen and (max-width: 600px){
  .container{
    margin-top: 50px;
  }

  .container .my-contacts{
    display: block;
    margin: 80px 0 20px 0;
    text-align: center;
  }


  .container .my-contacts .whatsapp{
    margin-left: 100px;
    padding-top: 10px;
    width: 170px;
    height: 50px;
    background-color: #001925;
    border-left: 4px #ffa500 solid;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  }
  .container .my-contacts .whatsapp a{
    color: #fff;
    font-size: 18px;
    text-decoration: none;
  }
  .container .my-contacts .whatsapp a .fa-whatsapp{
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    height: 27px;
    width: 27px;
    padding-top: 2px;
    background-color: #14a76c;
    border-radius: 10px;
    margin-right: 12px;
  }
  .container .my-contacts .instagram{
    margin-left: 100px;
    margin-top: 14px;
    padding-top: 10px;
    width: 170px;
    height: 50px;
    background-color: #001925;
    border-left: 4px #ffa500 solid;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
  }
.container .my-contacts .instagram a{
 
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.container .my-contacts .instagram a .fa-instagram{
  color: transparent;
  background: -webkit-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -o-radial-gradient(30% 107%, circle, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  margin-right: 10px;
}
.container .my-contacts .snapchat{
  margin-left: 100px;
  margin-top: 14px;
  padding-top: 10px;
  width: 170px;
  height: 50px;
  background-color: #001925;
  border-left: 4px #ffa500 solid;

  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
}
.container .my-contacts .snapchat a{
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.container .my-contacts .snapchat a .fa-snapchat{
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

.container .my-contacts .landline{
  margin-left: 100px;
  margin-top: 14px;
  width: 170px;
  height: 50px;
  background-color: #001925;
  border-left: 4px #ffa500 solid;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
}
.container .my-contacts .landline a{
  color: #fff;
  font-size: 20px;
  padding-bottom: 10px;
}

}

.about{
  margin-top: 120px;
  display: block;
  position: relative;
  margin-left: 10%;
}

.about .seat{
  width: 70%;
  display: block;
}

.about .seat p{
  padding-top: 15px;
  font-size: 25px;
  color: #444;
  line-height: 25px;
}

.h3{
  font-size: 35px;
  color: #222;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 30px;
  text-decoration: solid #001925 7px !important;
}

.about .seat p b{
  color: #2462c1;
  font-weight: 400;
}

.about .seat .images{
  display: block;
  width: 100%;
}

.about .seat .images .first{
  display: flex;
}

.about .seat .images .first .b{
  width: 600px;
  height: 650px;
}

.about .seat .images .first .s{
  width: 100%;
  height: 650px;
  padding-left: 10px;
}

.about .seat .images .last img{
  width: 100%;
  height: 650px;
  padding-top: 10px
}

.about .seat p a{
  text-decoration: #2462c1;
  color: #2462c1;
  font-size: 25px;
  
}

.operating-hrs{
  display: block;
  margin: 20px 0;
  margin: 0 15% 0 10%;

}
.operating-hrs h3{
  text-decoration: underline #001925 5px !important;
  font-size: 25px;
  color: #2462c1;
  font-weight: 400;
  margin-bottom: 10px;
}

.operating-hrs ul li{
  display: block;
  list-style-type: none;
  color: #444;
  padding-top: 3px;
  font-size: 20px;
}

.operating-hrs ul li .fa-solid{
  color: #87a4b6;
}
 
.about .location{
  display: block;
  margin-left: 20%;
}

.about .location h2{
  text-decoration: underline #001925 5px !important;
  font-size: 25px;
  color: #2462c1;
  font-weight: 400;
  margin-bottom: 10px;
}
.about .location .gmap{
  display: block;
}

.about .location .small{
  display: none;
}

.abt{
  background-color: #f3cbad;
  height: max-content;
  width: 100%;
  padding: 15px;
}
.abt2{
  margin-top: 30px;
  background-color: #97aabd;
  height: max-content;
  width: 100%;
  padding: 15px;
}
.abt3{
  margin-top: 30px;
  background-color: #f0db95;
  height: max-content;
  width: 100%;
  padding: 15px 5px;
}
@media only screen and (min-width: 610px) and (max-width: 1024px){
  
.about{
  margin-top: 120px;
  display: block;
  position: relative;
  margin-left: 5%;
}

.about .seat{
  width: 100%;
  display: block;
}

.about .seat p{
  padding-top: 15px;
  font-size: 25px;
  color: #444;
  line-height: 25px;
}

.h3{
  font-size: 35px;
  color: #222;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 30px;
  text-decoration: solid #001925 7px !important;
}

.about .seat p b{
  color: #2462c1;
  font-weight: 400;
}

.about .seat .images{
  display: block;
  width: 100%;
}

.about .seat .images .first{
  display: flex;
}

.about .seat .images .first .b{
  width: 280px;
  height: 450px;
}

.about .seat .images .first .s{
  width: 100%;
  height: 450px;
  padding-left: 10px;
}

.about .seat .images .last img{
  width: 100%;
  height: 450px;
  padding: 10px 12px 4px 12px;

}
.about .seat .images .new{
  display: flex;
  justify-content: center;
}
.about .seat .images .new img{
  width: 500px;
  height: 600px;
}
.about .seat p a{
  text-decoration: #2462c1;
  color: #2462c1;
  font-size: 25px;
  
}

.operating-hrs{
  display: block;
  margin: 20px 0;
  margin-left: 20%;

}
.operating-hrs h3{
  text-decoration: underline #001925 5px !important;
  font-size: 25px;
  color: #2462c1;
  font-weight: 400;
  margin-bottom: 10px;
}

.operating-hrs ul li{
  display: block;
  list-style-type: none;
  color: #444;
  padding-top: 3px;
  font-size: 20px;
}

.operating-hrs ul li .fa-solid{
  color: #87a4b6;
}
 
.about .location{
  display: block;
  margin: 0 4%;
}

.about .location h2{
  text-decoration: underline #001925 5px !important;
  font-size: 25px;
  color: #2462c1;
  font-weight: 400;
  margin-bottom: 10px;
}
.about .location .gmap{
  display: none;
}

.about .location .small{
  display: block;
}

.abt{
  background-color: #f3cbad;
  height: max-content;
  width: 100%;
  padding: 15px;
}
.abt2{
  margin-top: 30px;
  background-color: #97aabd;
  height: max-content;
  width: 100%;
  padding: 15px;
}
.abt3{
  margin-top: 30px;
  background-color: #f0db95;
  height: max-content;
  width: 100%;
  padding: 15px 5px;
}
}
@media only screen and (max-width: 600px){
  
.about{
  margin: 80px 0 0 0;
  display: block;
  position: relative;
  
}

.about .seat{
  width: 100%;
  display: block;
}

.about .seat p{
  padding: 10px 20px 0 20px;
  font-size: 23px;
  color: #222;
  line-height: 28px;
  
}

.h3{
  font-size: 30px;
  color: #222;
  font-weight: 400;
  margin-bottom: 10px;
  text-decoration: none;
}

.about .seat p b{
  color: #001925;
  font-weight: 600;
}

.about .seat .images{
  margin: 25px 0;
  display: block;
  width: 100%;
}

.about .seat .images .first{
  display: flex;
}

.about .seat .images .first .b{
  width: 160px;
  height: 250px;
}

.about .seat .images .first .s{
  width: 100%;
  height: 250px;
  padding-left: 2px;
  padding-right: 8px;
}

.about .seat .images .last img{
  width: 100%;
  height: 360px;
  padding: 4px 8px 4px 8px;
}
.about .seat .images .new{
  display: flex;
  justify-content: center;
}

.about .seat .images .new img{
  width: 330px;
  height: 400px;
}
.about .seat p a{
  text-decoration: underline #2462c1 !important;
  color: #2462c1;
  font-size: 20px;
  font-weight: bold;
}

.operating-hrs{
  text-align: left;
  display: block;
  margin: 20px 0 20px 40px;
}
.operating-hrs h3{
  text-decoration: underline #001925 5px!important;
  font-size: 25px;
  color: #2462c1;
  font-weight: 400;
  margin-bottom: 10px;
}

.operating-hrs ul li{
  display: block;
  list-style-type: none;
  color: #444;
  padding-top: 3px;
  font-size: 20px;
}

.operating-hrs ul li .fa-solid{
  color: #87a4b6;
}
 
.about .location{
  text-align: center;
  display: block;
  margin: 0;
}

.about .location h2{
  text-decoration: underline #001925 5px!important;
  font-size: 25px;
  color: #2462c1;
  font-weight: 400;
  margin-bottom: 10px;
}
.about .location .gmap{
  display: none;
}

.about .location .small{
  display: flex;
  justify-content: center;
}
.abt{
  background-color: #f3cbad;
  height: max-content;
  width: 100%;
  padding: 15px 0;
}
.abt2{
  margin-top: 30px ;
  background-color: #97aabd;
  height: max-content;
  width: 100%;
  padding: 15px 5px;
}
.abt3{
  margin-top: 30px;
  background-color: #f0db95;
  height: max-content;
  width: 100%;
  padding: 5px 0;
}
}
.main-loader{
  display: block;
  background-color: #fff;
  height: 100vh;
  width: 100%;
}

@keyframes square-animation {
  0% {
   left: 0;
   top: 0;
  }
 
  10.5% {
   left: 0;
   top: 0;
  }
 
  12.5% {
   left: 32px;
   top: 0;
  }
 
  23% {
   left: 32px;
   top: 0;
  }
 
  25% {
   left: 64px;
   top: 0;
  }
 
  35.5% {
   left: 64px;
   top: 0;
  }
 
  37.5% {
   left: 64px;
   top: 32px;
  }
 
  48% {
   left: 64px;
   top: 32px;
  }
 
  50% {
   left: 32px;
   top: 32px;
  }
 
  60.5% {
   left: 32px;
   top: 32px;
  }
 
  62.5% {
   left: 32px;
   top: 64px;
  }
 
  73% {
   left: 32px;
   top: 64px;
  }
 
  75% {
   left: 0;
   top: 64px;
  }
 
  85.5% {
   left: 0;
   top: 64px;
  }
 
  87.5% {
   left: 0;
   top: 32px;
  }
 
  98% {
   left: 0;
   top: 32px;
  }
 
  100% {
   left: 0;
   top: 0;
  }
 }


 .main-loader{
  width: 100%;
  height: 100%;
 }

 .main-loader .photo{
  width: 100%;
  height: 100vh;
 }

 .main-loader .photo img{
  width: 100%;
  height: 100vh;
 }
  
 @media only screen and (max-width: 600px){
  .main-loader{
    width: 100%;
    height: 100%;
   }
  
   .main-loader .photo{
    width: 100%;
    height: 100vh;
   }
  
   .main-loader .photo img{
    position: relative;
    width: 100%;
    height: 100vh;
   }
 }
 .main-loader .Loader{
  display: flex;
  justify-content: center;
  padding: 120px 0px;
 }
 .main-loader .Loader .loader {
  position: relative;
  width: 96px;
  height: 96px;
  transform: rotate(45deg);
 }
 
 .loader-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 0px;
  background: #6F4E37;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation 10s ease-in-out infinite both;
 }
 
 .loader-square:nth-of-type(0) {
  animation-delay: 0s;
 }
 
 .loader-square:nth-of-type(1) {
  animation-delay: -1.4285714286s;
 }
 
 .loader-square:nth-of-type(2) {
  animation-delay: -2.8571428571s;
 }
 
 .loader-square:nth-of-type(3) {
  animation-delay: -4.2857142857s;
 }
 
 .loader-square:nth-of-type(4) {
  animation-delay: -5.7142857143s;
 }
 
 .loader-square:nth-of-type(5) {
  animation-delay: -7.1428571429s;
 }
 
 .loader-square:nth-of-type(6) {
  animation-delay: -8.5714285714s;
 }
 
 .loader-square:nth-of-type(7) {
  animation-delay: -10s;
 }
.cafe{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  color: #6F4E37;
  text-align: center;
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.cafe .caffee{
  -webkit-animation: focus-in-contract-bck 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	   animation: focus-in-contract-bck 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-2-24 3:26:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
 @-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
} 