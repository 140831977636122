  @import url('https://fonts.googleapis.com/css2?family=Lobster+Two:wght@400;700&family=Quicksand:wght@400;500;600;700&family=Roboto+Condensed:wght@100;200;300;400;500;600&family=Roboto:wght@100;300;400;500;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');
 

body{
  font-family: 'Lobster Two', sans-serif;
font-family: 'Quicksand', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
.navbar{
  margin: 0;
  width: 100%;
  display: block;
  width: 100%;
  background-color: #6F4E37;
  /* background: linear-gradient(to right, #868f96 0%, #37393a  100%); */
}


.navbar .top{
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  position: fixed;
  margin: 0;
  z-index: 1;
  top: 0;
  background-color: #6F4E37;
  /* background: linear-gradient(to right, #868f96 0%, #37393a  100%); */
  width: 100%;
}


.links{
  display: flex;
}

.navbar .top img{
  width: 200px;
  height: 90px;
  border-radius: 40px;
}

.navbar .top a{
  padding: 0 10px;
  text-decoration: none;
  color: #ebebeb;
  font-size: 30px;
  font-weight: 500;
}

.navbar .top a.active{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6F4E37;
  color: #ebebeb;
  border: 4px solid #ebebeb;
  border-radius: 10px;
}

.bottom{
  padding-top: 12px;
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 90px;
  background-color: #6F4E37;
  /* background: linear-gradient(to right, #868f96 0%, #37393a  100%); */
}



.nav .bottom .new{
  align-items: center;
  z-index: 1;  
  padding-top: 0;
  background-color: #6F4E37;
  /* background: linear-gradient(to right, #868f96 0%, #37393a  100%); */
  height: auto;
  display: flex;
  
}

.nav .bottom  .new a{
  text-decoration: none;
  white-space: nowrap;
  padding: 3px;
  color: #6F4E37;
  border-radius: 0;
  margin: 0 4px;
  background-color: #fff;
  width: fit-content;
  font-weight: 900;
  font-size: 25px;
  border: 3px solid #6F4E37;
 
}
.nav .bottom  .new a.active{
  color: #fff;
  background-color: #6F4E37;
  border: 6px solid  #fff;
  font-weight: bold;
}


@media only screen and (max-width: 600px) {
	.navbar{
    background: #6F4E37 ;
    margin: 0;
    display: block;  
  }
  
  .navbar .top{
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    position: fixed;
    margin: 0;
    width: 100%;
    height: 55px;
    z-index: 1;
    background: #6F4E37
    ;
    /* background: linear-gradient(to right, #868f96 0%, #37393a 100%); */
    top: 0;
  }
  
  .links{
    display: flex;
    justify-content: space-between;
  }

  .navbar .top img{
    width: 100px;
    height: 45px;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  
  .navbar .top a{
    padding-right: 4px;
    text-decoration: none;
    color: #fff;
    font-size: 22px;
     font-weight: 900;
     height: 35px;
     background-color: #6F4E37;
  }
  .navbar .top a.active{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #6F4E37;
    border: 3px solid #fcfcfc;
    border-radius: 10px;
  }
  .bottom{ 
  display: block;
  background: #22c1c3;
  background: #6F4E37;
  /* background: linear-gradient(to right, #868f96 0%, #37393a 100%); */
  position: fixed;
  top: 50px;
  z-index: 1;
  margin: 0;
}

.bottom .fa-solid{
  display: none;
}
.nav .bottom .new{
  background: #6F4E37
  ;
  /* background: linear-gradient(to right, #868f96 0%, #37393a 100%); */
  display: flex;
   flex-wrap: nowrap; 
  overflow-x: auto;
  align-items: center;
  align-content: center;
  margin: 0;
  height: 50px;
}
.nav .bottom .new::-webkit-scrollbar{
  display: none;
}

.nav .bottom .new a{
  text-decoration: none;
  white-space: nowrap;
  padding: 3px;
  color: #fff;
  border-radius: 4px;
  margin: 0 2px;
  background: #6F4E37;
  width: fit-content;
  font-weight: 500px;
  font-size: 18px;
  border: 2px solid #fff;
}

.nav .bottom  .new a.active{
  color: #6F4E37;
  background-color: #fff;
  border: 2px double #6F4E37;
}

}

@media only screen and (min-width: 610px) and (max-width: 1024px)  {
  

.navbar{
  margin: 0;
  width: 100%;
  display: block;
  background: #6F4E37
  ;
}

.navbar .top{
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  position: fixed;
  margin: 0;
  z-index: 1;
  background: #6F4E37
  ;
  width: 100%;
}


.links{
  display: flex;
  justify-content: space-between;
}

.navbar .top img{
  width: 170px;
  height: 70px;
  border-radius: 35px;
}

.navbar .top a{
  padding: 0 10px;
  text-decoration: none;
  color: #fff9f9;
  font-size: 30px;
  font-weight: 600;
}



.bottom{
  display: block;
  background: #6F4E37
  ;
  position: fixed;
  top: 70px;
  z-index: 1;
  margin: 0;
}


 .nav .bottom .new{
  width: 100%;
  background: #6F4E37
  ;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: center;
  align-content: center;
  margin: 0;
 }
 .nav .bottom .new::-webkit-scrollbar{
   width: 0;
 }
.nav .bottom  .new a{
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  padding: 3px;
  cursor: pointer;
  color: #fcfcfc;
  font-size: 20px;
  background-color: #6F4E37;
  border: solid 4px #fff9f9;
  border-radius: 15px;
  font-weight:bold;
  z-index: 1;

}


}
 
.big{
  display: block;
  background: linear-gradient(to right, #868f96 0%, #596164 100%);
  margin-top: 90px;
}
.big .another{
  margin-left: 3px;
  background: #b5b1b1;
  padding-top: 90px;
}
.big .h1{
    
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: relative;
    cursor: pointer;
  
  
}
.big .h1 h1{
  color: #6F4E37;
  width: 100%;
  height: 100%;
  font-size: 28px;
  font-weight: 900;
}
.breakfast{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  
}

.bfast{
  width: 350px;
  padding-top: 10px; 
  display: block;
  flex-basis: 25%;
}

.bfast img{
  width: 350px;
  height: 400px;
  border-radius: 15px;
}

.bfast .head{
    display: flex;
    justify-content: left;
    align-content: center;
    align-items: center;
}
.bfast h3{
    font-size: 25px;
    color: #282828;
    font-weight: bold;
}

.bfast h5{
  padding-left: 10px;
  font-size: 20px;
  color: #0047ab;
}
 .bfast .detail{
  width: 300px;
  color: #222;
 }
 
@media only screen and (min-width: 610px) and (max-width: 1024px){
  
.big{
  background: linear-gradient(to right, #868f96 0%, #596164 100%);
  display: block;
  margin-top: 65px;
  margin-left: 10px;
}

.big .another{
  margin-left: 3px;
  padding-top: 100px;
  background: #b5b1b1;
}
.big .h1 h1{
  color: #6F4E37;
  width: 100%;
  height: 100%;
  font-size: 28px;
  font-weight: 900;
}
.breakfast{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
}

.bfast{
  width: 350px;
  padding-top: 10px; 
  display: block;
  flex-basis: 30%;

}

.bfast img{
  width: 270px;
  height: 370px;
  border-radius: 15px;
  cursor: pointer;
}

.bfast .head{
    display: flex;
    justify-content: left;
    align-content: center;
    align-items: center;
}
.bfast h3{
    font-size: 20px;
    color: #282828;
    font-weight: bold;
}

.bfast h5{
  padding-left: 2px;
  font-size: 17px;
  color: #0047ab;
}
 .bfast .detail{
  width: 300px;
  color: #555;
 }
 
}

 @media only screen and (max-width: 600px){


  
.big{
  display: block;
  margin-top:50px;
  background: linear-gradient(to right, #868f96 0%, #596164 100%);
}
.big .another{
  margin-left: 3px;
  padding-top: 120px;
  background-color: #b5b1b1;
}
.big .h1{
 padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
  cursor: pointer;

}

.big .h1 h1{
  text-decoration: overline solid 2px #3b3bbb;
  color: #6F4E37;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 900;

}
.breakfast{
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
 
}

.bfast{
  width: 180px;
  margin-top: 10px; 
  display: block;
  flex-basis: 50%;
  height: auto;
  
}

.bfast img{
  width: 100%;
  height: 240px;
  border-radius: 20px;
  cursor: pointer;
  padding-right: 5px;
}

.bfast .head{
   display: block;
}
.bfast h3{
    font-size: 18px;
    color: #000;
    font-weight: bold;
    
}

.bfast h5{
  padding-top: 5px;
  font-size: 16px;
  font-weight: 800;
  color: #3b3bbb;
}
 .bfast .detail{
  width: 140px;
  color: #222;
  font-size: 9px
 }

 }

 .my-footer{
  display: block;
  background-color: #6F4E37;
 }

 .my-footer .footer{
  display: flex;
  background-color: #6F4E37;
  justify-content: center;
  height: 150px;
  width: 100%;
  align-items: center;
  align-content: center;
  position: relative;
  bottom: 0;
}

.my-footer .footer img{
  width: 250px;
  height: 100px;
  border-radius: 15px;
}

.my-footer .h6{
  text-align: center;
  font-size: 10px;
  color: #999;
}

.my-footer a{
  text-align: center;
  font-size: 10px;
  color: #2975c1;
}

 @media only screen and (max-width: 600px){

  .my-footer .footer{
    display: flex;
    background-color: #6F4E37;
    justify-content: center;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0;
  }
  
  .my-footer  .footer img{
    width: 100px;
    height: 50px;
    border-radius: 12px;
  }
  .my-footer .h6{
    text-align: center;
    font-size: 9px;
    color: #999;
  }
  
  .my-footer a{
    display: flex;
    text-align: center;
    font-size: 9px;
    color: #2975c1;
  }
 }















